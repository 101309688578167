<template>
  <div class="basic-input" @click="handleClick">
    <div class="label-top" :class="{top:placeholderTop}">{{ placeholderComputed }}</div>
    <input
      ref="input"
      :class="{error}"
      :value="internalValue"
      :name="name"
      :required="required"
      :autocomplete="autocomplete"
      :type="type"
      @change="$emit('change',$event.target.value)"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="$emit('input',$event)"
    >
    <slot />
    <div v-if="showIcon" class="icon" :class="{error,right}" />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    error: String,
    right:Boolean,
    autocomplete:String,
    showIcon: { type: Boolean, default: true },
    placeholder: String,
    required: {
      type: Boolean,
      default: false,
    },
    type: String,
    label: String,
    name: String
  },
  data() {
    return {
      internalValue: this.value,
      focus:false
    }
  },
  computed:{
    placeholderTop(){
      return this.focus || !!this.value
    },
    placeholderComputed(){
      let text = ''
      text+=this.placeholder
      if(this.required) text+='*'
      return text
    }
  },
  watch: {
    value: {
      handler() {
        this.internalValue = this.value
      },
      immediate: true,
    },
  },
  methods:{
    handleFocus(event){
      this.focus=true
      this.$emit('focus',event)
    },
    handleBlur(event){
      this.focus=false
      this.$emit('blur',event)
    },
    handleClick(){
      this.$refs.input?.focus()
    }
  },
}
</script>

<style lang="stylus" scoped>
$error-color = #e6373c
.basic-input
  position: relative
  min-width: vw(100px)
  height: vw(60px)
  margin vh(10px) vw(10px)
  .label-top
    position: absolute
    color: $dark-gray
    top: 50%
    transform: translateY(-50%)
    transition: all .2s ease-in-out
    left vw(20px)
    &.top
      m-font-size(10,12)
      transform: translateY(0)
      top vh(5px)
      left vw(20px)
  > input
    padding-right: vw(60px)
    padding-left: 1em
    min-width: vw(100px)
    width: 100%
    height: 100%
    border: solid 1px $dark-gray
    border-radius: 4px

    &::placeholder
      color: $dark-gray

    &:focus-visible,
    &:active
      outline-color: $primary-theme-color

    &.error
      border-color: $error-color

  .icon
    position: absolute
    top: 50%
    right: vw(15px)
    transform: translateY(-50%)
    &.right
      m-icon("selected-green", 36)
    &.error
      m-icon("warning", 20)
</style>